import React from 'react';
import {ImageAtom, TextAtom} from "../atoms";
import {TextWithRightArrowIcon} from "../molecules";



function ServicesTabOne({title, para, img, handleOpenModal}) {
  console.log(para.length, 'l');
  return <div className={title === "Game Development Services" ? "flex justify-center items-center bg-gradient-to-t from-white via-white to-gray-200": "flex justify-center items-center bg-gray-200"}>
    <div className="md:m-10 lg:flex w-12/12 lg:max-w-screen-xl ">
    <ImageAtom divStyle="lg:w-5/12 lg:mt-0 mt-10" img={img} />
      <div className="lg:w-7/12 lg:p-10 p-5 flex justify-center items-center">
        <div className="p-5">
        <TextAtom textStyle="font-nunito_Sans text-red-500" divStyle="" text="PLANNING" />
        <TextAtom divStyle="" textStyle="lg:text-4xl md:text-2xl text-xl font-spartan" text={title} />
        {para.map(item => (
          <TextAtom textStyle="font-nunito_Sans text-gray-800 text-justify " divStyle="my-4 text-justify" text={item}/>
        ))} 
          <div className="flex">

      {para.length === 1 ? <TextWithRightArrowIcon handleOpenModal={handleOpenModal}  text="Contact Us"/>: null} 
          </div>
   </div>
 
    </div>
  </div>
    </div>

}

export {ServicesTabOne};
