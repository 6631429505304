import React, { Component } from 'react';
import {TextAtom} from "../view/atoms";

class ErrorBoundary extends Component {


  constructor(props) {
    super(props)
    this.state = {
      hasError:false
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError:true
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
  }


  render() {
    if (this.state.hasError) {
      return <div>

        <TextAtom divStyle="flex justify-center" textStyle="text-2xl leading-3 mt-12 text-gray-800 font-spartan" text="Something went Wrong" />
      </div> 
    }
    return this.props.children
  }
}

export default ErrorBoundary;
