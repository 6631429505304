import React from 'react';
import {TextAtom} from "../view/atoms";

function NotFound() {
  return <div className="flex justify-center items-center  h-screen bg-gray-200">
    <div>
    <TextAtom divStyle="flex justify-center" textStyle="text-8xl text-gray-800 leading-10 font-spartan" text="404"/>
      <TextAtom divStyle="flex justify-center" textStyle="text-2xl leading-3 mt-12 text-gray-800 font-spartan" text="Not Found" />
      <TextAtom divStyle="flex justify-center mt-5" textStyle="text-lg text-gray-800 font-nunito_Sans" text="Your requested url is not found" />
      </div>
  </div>;
}

export default NotFound;
