import React from 'react';
import {ImageAtom, TextAtom} from "../view/atoms";
import {TextWithRightArrowIcon} from "../view/molecules";

function ExpertiseCard({title,para, img}) {
  return <div className="lg:w-86 shadow-xl px-5 mx-2 py-2 border-2 rounded-md my-5 ">
    <div className="flex mr-40"> 
      <ImageAtom imageStyle="h-20 my-5" img={img} />
      </div>
    <TextAtom divStyle="py-2" textStyle="py-2 font-semibold text-2xl" text={title} />
    <TextAtom textStyle="py-2" text={para} />
    {/* <TextWithRightArrowIcon path="/contact" text="READ MORE"/> */}
  </div>;
}

export default ExpertiseCard;
