import React from 'react';
import {ImageAtom, TextAtom} from "../atoms";

function ProductListDital({list, handleImageChange}) {
  return list.map(item => (
    <div onMouseEnter={() => handleImageChange(item.screenShot)} className="lg:flex lg:w-118 cursor-pointer md:p-2 lg:p-0 p-0 mt-8">
    <ImageAtom divStyle=" lg:ml-10  flex justify-center items-center" imageStyle=" w-32 "    img={item.img} />
      <div className="lg:mx-5 flex justify-center items-center ">
        <div>
    <TextAtom text={item.title} divStyle="" textStyle="text-xl text-gray-800 font-semibold"/>
          <TextAtom text={item.para} divStyle="" textStyle="text-sm text-gray-700 font-nunito_Sans" />
          </div>
      </div>
    
  </div>
  ))
}

export {ProductListDital};
