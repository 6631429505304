import React from 'react';
import { TextAtom } from "../view/atoms";
import ExpertiseCard from "./ExpertiseCard";

import custom_software from '../assets/images/custom_software.png'
import application from '../assets/images/application.png'
import mobile_application from '../assets/images/mobile_application.png'
import design_idea from '../assets/images/design_idea.png'
import game_application from '../assets/images/game_application.png'
import virtual_reality from '../assets/images/virtual_reality.png'

const expertise = [
  {
    img: custom_software,
    title: "Custom software development",
    para: "Get a custom solution developed tailored to your requirements to elevate your business profits.",
  },
  {
    img: application,
    title: "Web applications development",
    para: "We develop customized, and domain specific web application to meet your demands cost-effectively.",
  },
  {
    img: mobile_application,
    title: "Mobile application development",
    para: "Get end-to-end mobile app development from business analysis to deployment.",
  },
  {
    img: design_idea,
    title: "User experience research & design",
    para: "UX design accounts for half of the app’s success. Get a custom design by our experienced UI/UX experts.",
  },
  {
    img: game_application,
    title: "Mobile Games development",
    para: "Get end-to-end 2D and 3D animated games development we’re designing games based on the requirements and also for the every age",
  },
  {
    img: virtual_reality,
    title: "Extended Reality (AR, & VR)",
    para: "With hands-on expertise in offering innovative AR, & VR solutions such as VR-based training",
  },
]

function OurExpertise() {
  return <div className="bg-darkblue"> <div className="flex justify-center items-center bg-white md:rounded-tl-15xl  py-5 px-6 md:py-20">

    <div className="max-w-screen-xl md:mx-20">
      <TextAtom divStyle="lg:ml-24" textStyle="text-gray-700 md:text-xl text-md font-semibold" text="OUR EXPERTISE" />
      <TextAtom divStyle="lg:ml-24" textStyle="font-bold lg:text-4xl md:text-2xl text-xl font-spartan my-5 leading-tight" text="Software Product Development Teams" />
      <div className="flex flex-wrap lg:justify-center">
        {expertise.map(item => (
          <ExpertiseCard img={item.img} title={item.title} para={item.para} />
        ))}
      </div>
    </div>
  </div>
  </div>
};

export default OurExpertise;
