import React from "react";
import { TextAtom } from "../view/atoms";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { CurrentProjectCard } from "../components/CurrentProjectCard";
import { SlidingData } from "../view/organisms";
import {BorderWithText} from "../view/molecules";
import Slider from "react-slick";
import "../components/banner_slider/style.css";

const BsCardList = [
  {
    text: "School Management",
  },
  {
    text: "Notebook Elite Magic Everyday",
  },
  {
    text: "Home Kitchen Konnect",
  },
  {
    text: "Big Biking Commune",
  },
];

function Technologies({list, borderText, slidesToShow}) {
   const settings = {
    infinite: true,
    lazyload: true,
    arrows: false,
    speed: 1500,
    slidesToShow: slidesToShow,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    adaptiveHeight: true,
    variableWidth: true
    
  };
  return (
    <div>
      <div className="lg:w-4/12 px-5 lg:px-12 ">
        <TextAtom
          textStyle="text-white leading-8 font-semibold"
          text="TECHNOLOGIES"
        />
      </div>
    
         <div className="py-2">
      <BorderWithText text={borderText} />
      <div className="mt-6">
          <>
      <Slider  adaptiveHeight={true} {...settings}>
        {list.map((img) => (
          <div className="lg:pb-2 pb-5 flex items-center" key={img}>
            {/* <img className={isMobileView ? "bg-cover" : text === "T E C H N O L O G I E S" ?  " h-20 mt-2": " h-40 mt-2"} src={img} alt="isl" /> */}
            <div className={"lg:w-56 lg:h-20 w-20 h-12 bg-contain" } style={{backgroundImage:`url('${img}')`,backgroundRepeat:'no-repeat'}}/>
          </div>
        ))}
      </Slider>
    </>
      </div>
    </div>
      
    </div>
  );
}

export default Technologies;
