import React from 'react';
import {ImageAtom, TextAtom} from "../view/atoms";
import {TextWithRightArrowIcon} from "../view/molecules";

function CurrentProjectCard({text, textStyle, img}) {
  console.log(img,'img');
  return <div className="drop-shadow-2xl shadow-xl w-72 my-2 md:mx-3 rounded-lg mx-auto bg-white">
  {img === undefined ?  <div className="h-56 bg-black"></div>: <ImageAtom imageStyle="rounded-tr-lg bg-cover rounded-tl-lg w-full bg-cover" divStyle="w-full" img={img} /> } 
    <TextAtom divStyle="w-56 mx-4 my-3" textStyle={textStyle} text={text}/>
      {/* <TextWithRightArrowIcon text="
READ MORE
" path="/contact"/> */}
       
  </div>;
}

export {CurrentProjectCard};
