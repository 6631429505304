import React from 'react';

import {ClientsTemplate} from '../view/templates'


function Clients() {
  return <div>
    <ClientsTemplate/>
  </div>;
}

export default Clients;
