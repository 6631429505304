import React from 'react';
import { ImageAtom, TextAtom } from "../view/atoms";

function AboutCard({ Icon, title, para }) {
  return <div className="lg:w-86 shadow-xl px-5 mx-2 py-10 rounded-md my-5 ">
    <div className="flex mr-40">
      {/* <ImageAtom imageStyle="h-20 my-5" img="http://illusionspotline.com/wp-content/uploads/2021/05/school.jpg" /> */}
      <Icon className="h-20 w-20 text-red-500 hover:text-blue-500" />
    </div>
    <TextAtom divStyle="py-2 text-black" textStyle="py-2 font-semibold text-2xl" text={title} />
    <TextAtom textStyle="py-2 text-black text-justify" text={para} />
  </div>;
}

export default AboutCard;
