import React, { useState, useEffect, useRef } from 'react';
import map from '../../assets/images/googlemap.png'

import GoogleMapReact from 'google-map-react';

import Map, { Marker } from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

function ContactTabThree() {
  const [center, setCenter] = useState({ lat: 11.0168, lng: 76.9558 });
  const [zoom, setZoom] = useState(11);
  const [screenSize, setScreenSize] = useState(false);
  //const screenSize = useRef(false);

  const handleScreenSize = () => {

    if (window.innerWidth < 720) {
      setScreenSize(true)
    } else {

      setScreenSize(false)
    }
    console.log(window.screen.width, 'log');
  }

  useEffect(() => {
    console.log(window.screen.width, 'width');

    if (window.innerWidth < 720) {
      setScreenSize(true)
    } else {

      setScreenSize(false)
    }
    console.log();
    window.addEventListener('resize', handleScreenSize)
  }, [center]);

  return <div className="lg:flex w-12/12">
    <div className={screenSize ? "h-96 w-12/12" : "lg:w-6/12 w-12/12 w-full lg:mx-auto lg:ml-20"} style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/mapImagel.png?alt=media&token=d6f04870-ede3-44dd-b2e6-b8a25ce5c195')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

    </div>
    <div className="lg:w-6/12">
      <div >
        {/* <Map
          initialViewState={{
            latitude: 13.09,
            longitude: 80.27,
            zoom: 12
          }}
          style={{ width: "100vw", height: "100vh" }}
          mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
        >
          <Marker longitude={-122.4} latitude={37.8} color="red" />
        </Map> */}
        <a href="https://goo.gl/maps/aiLMyrTujcAt9zeb9" target="_blank">
          <img src={map} /></a>
      </div>
    </div>
  </div>;
}

export { ContactTabThree };
