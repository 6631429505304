import React,{useState} from 'react';
import {BsChevronBarDown} from "react-icons/bs";
import {TextAtom} from "../atoms";

function TitlePara({title, para}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(state=>!state)
  }
  return <div className="py-1 p-5 border-2">
    <div className="py-2 flex justify-between items-center" onClick={()=>handleOpen()}>
      <TextAtom textStyle="text-lg font-semibold" text={title} />
      <BsChevronBarDown className={open ? "rotate-180 transform duration-300" : "rotate-0 transform duration-300"}/>
      
    </div>
   
    {open === true ? <TextAtom textStyle="font-nunito_Sans text-gray-800" text={para}/>: null}
    
  </div>;
}

export {TitlePara};
