import React from 'react';
import {ProcessWorkCard} from "../../components/ProcessWorkCard";
import {TextAtom} from "../atoms";
import {TextWithRightArrowIcon} from "../molecules";

function WebDesigningTabThree({list, handleOpenModal}) {
  return <div className="flex justify-center items-center bg-gradient-to-b from-gray-200 ">
    <div className="md:m-10 lg:flex lg:justify-center w-12/12 lg:max-w-screen-xl">
    <div className="lg:w-10/12 lg:px-10 p-5">
        <TextAtom textStyle="font-nunito_Sans text-red-500" divStyle="" text="PLANNING" />
        <TextAtom divStyle="py-2" textStyle="lg:text-4xl md:text-2xl text-xl font-spartan" text="How Development Process Works" />
        <TextAtom divStyle="lg:py-2" textStyle="text-md font-nunito_Sans text-gray-700" text="At Illusion Spot Line we follow the agile development process and our highly experts will make every possible transition through out the process." />
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 p-5">
        {list.map(item => (
          <ProcessWorkCard img={item.img} title={item.title} stage={item.stage}/>
       ))} 
        </div>
         <TextWithRightArrowIcon handleOpenModal={handleOpenModal} path="/contact" text="GET QUESTIONNAIRE"/>
      </div>
     
  </div>
    </div>;
}

export {WebDesigningTabThree};
