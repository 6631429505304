import React from 'react';
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import {ServicesTabOne} from "../organisms";
import BrandingTabTwo from "../organisms/BrandingTabTwo";

import branding from '../../assets/images/branding.png'

const para = [
  'We’ve been creating strong brands for both socially and environmentally conscious companies thus creating a strong footprint for our clients.',
  'We are just a bunch of determined geeks with out-of-the-box ideas. Creativity is our drug and we certainly cannot live without it. Our proficient and adept team strives to ensure that our branding will be remembered forever and ever. We believe that a brand is an unifying, inspiring and everlasting experience. We at Brand mustache nurture and develop a good idea into a solid brand while executing all this without compromising on quality',
  'We have experienced designers that can produce creative logo design and corporate logo designs. We work hard to get an insight into your company and then offer options for you to select from in order to design a perfect logo suitable for marketing your brand. Afterwards we develop the logo and help you to integrate it wherever needed.'

  
]

function BrandingTemplate() {

  const [open, setOpen] = React.useState(false);
  
  const handleOpenModal = () => {
    console.log('click');
    setOpen(state=>!state)
  }
  return <>
     <Modal modalOpen={open} handleOpenModal={handleOpenModal}/>
     <div className="">
    <ServicesTabOne img={branding} title="Developing Powerful Brands and Identities" para={para} />
    <BrandingTabTwo handleOpenModal={handleOpenModal}/>
    <Footer/>
  </div>
    </>
}

export {BrandingTemplate};
