import React from 'react';
import {BrandingCard} from "../../components/BrandingCard";
import {TextAtom} from "../atoms";
import {TextWithRightArrowIcon} from "../molecules";

function ServicesTabTwo({title,description, list, handleOpenModal}) {
  return <div className="bg-blue-500 ">
    <div className="flex justify-center bg-white rounded-tr-6xl rounded-bl-6xl lg:py-20 py-10">
      <div className="">
        <TextAtom divStyle="flex justify-center" textStyle="lg:text-4xl md:text-2xl text-xl font-spartan" text={title} />
        <div className="flex justify-center">
          <TextAtom divStyle="max-w-screen-lg flex justify-center mx-16 lg:mx-0 lg:py-5 py-2" textStyle="text-md font-nunito_Sans text-gray-700 text-center" text={description} />
          </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 pb-2 lg:pb-5 mx-16 lg:mx-0 gap-5  max-w-screen-xl">
        
      {list.map((item) => (
        <BrandingCard img={item.img} title={item.title} para={ item.para}/>
      ))}
     
        </div>
       
        </div>
  </div>
    </div>;
}

export {ServicesTabTwo};

