import React from 'react';
import {ContactTemplate} from "../view/templates/ContactTemplate";

function Contact() {
  return <div>
    <ContactTemplate/>
  </div>;
}

export default Contact;
