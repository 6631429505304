import React from 'react';
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import {ServicesTabOne} from "../organisms";

import game from '../../assets/images/game.png'

const para = [
  'Web and mobile platforms. From side-scrolling 2D platformers to multi-player RPGs with VR integration to casual online, social and mobile games we’ve designed and developed a number of fun and exciting titles. We adapt to the latest tools and frameworks in the market and work with game engines like Unity3D, Cocos2d-x and Unreal as well as technologies like HTML5, Angular JS and others to build high quality games'
]

function GameDevelopmentTemplate() {
  
  const [open, setOpen] = React.useState(false);
  
  const handleOpenModal = () => {
    console.log('click');
    setOpen(state=>!state)
  }
  return<> <Modal modalOpen={open} handleOpenModal={handleOpenModal}/> <div>
    <ServicesTabOne handleOpenModal={handleOpenModal} img={game} title="Game Development Services" para={para} />
    <Footer/>
  </div>
    </>
}

export {GameDevelopmentTemplate};
