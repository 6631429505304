import React from 'react';
import {InputText, TextAtom} from "../atoms";

function InputWithText({text,placeholder,inputValue, name, type}) {
  return <div>
    <TextAtom textStyle="font-semibold" text={text}/>
      <InputText name={name} type={type} placeholder={placeholder} inputStyle=" outline-none w-full px-2 bg-gray-100 font-normal md:py-1  rounded-md" text="" />
      </div>
}

export {InputWithText};
