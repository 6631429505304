import React from 'react';
import { ProductsTemplate } from '../view/templates'
import { NewProducts } from '../view/templates/NewProducts';

function Products() {
  return <div>
    <ProductsTemplate />

    {/* <NewProducts /> */}
  </div >;
}

export default Products;
