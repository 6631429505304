import React from 'react';
import {WebDesigningTemplate} from "../view/templates";

function WebDesigning() {
  return <div>
    <WebDesigningTemplate/>
  </div>;
}

export default WebDesigning;
