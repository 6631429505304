import React from 'react';
import { TextAtom } from "../atoms";

function ContactTabOne() {
  return <div className="md:py-20 pt-20 md:pt-10" style={{ backgroundImage: `url("https://stockholdingdms.com/images/bg/testimonial-bg.jpg")` }}>
    <TextAtom divStyle="flex justify-center" textStyle="text-primary  font-semibold" text="CONTACT US" />
    <TextAtom divStyle="flex justify-center py-5" textStyle="lg:text-6xl md:text-5xl text-black text-2xl text-center lg:w-4/12 font-spartan  leading-tight" text="Let's Get Together and Work" />
  </div>;
}

export { ContactTabOne };
