import React from 'react';
import { TextAtom } from "../atoms";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { CurrentProjectCard } from "../../components/CurrentProjectCard";
import { TextWithRightArrowIcon } from "../molecules";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import kgplus from "../../assets/images/products/kgplus.jpg";
import intelligame from "../../assets/images/products/intelligame.jpg";
import p4u from "../../assets/images/products/p4u.jpg";
import voca from "../../assets/images/products/voca.jpg";
import kgprep from "../../assets/images/products/kgprep.jpg";

import SliderComp from "../templates/slider"

const BsCardList = [
  {
    img: intelligame,
    text: 'Memory Plus IntelliGame'
  },

  {
    img: kgplus,
    text: 'KG Plus'
  },
  // {
  //   img: kgprep,
  //   text: 'KG Prep'
  // },
  // {
  //   img: p4u,
  //   text: 'Planext4u'
  // },
  // {
  //   img: voca,
  //   text: 'VocaBuilder'
  // },
  // {
  //   img: 'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/school.jpg?alt=media&token=83c45a22-53d0-466f-a909-b4e4743bfe47',
  //   text: 'School Management'
  // },
  // {
  //   img: 'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/notebook.jpg?alt=media&token=463c9f01-97c3-419d-a6a2-e50190ddde96',
  //   text: 'Notebook Elite Magic Everyday'
  // },
  // {
  //   img: 'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/big.jpg?alt=media&token=3881b129-5eb4-431b-9730-d50359b221d4',
  //   text: 'Big Biking Commune'
  // },
  // {
  //   img: 'https://play-lh.googleusercontent.com/CGoaHXYqiIimNGXftLLBxCCgQ5SoraagiS2oj9KEKHj0oe56LJeowk0ZGgiVIaxZQnA=s180-rw',
  //   text: 'Rite Angle'
  // },




]
const list = [
  {
    text: 'Specialist Of Scalable Apps'
  },
  {
    text: 'Your Journey Partner'
  },
  {
    text: 'Deliver On Time'
  },
  {
    text: 'Deal Structuring'
  },
]

function CurrentProjects({ handleOpenModal }) {
  return <div className="md:flex justify-center md:mx-16 lg:mx-0">
    <div className="lg:my-20 my-5">
      <div className=" lg:mb-10 my-2  grid lg:grid-cols-4 gap-3 md:grid-cols-2 mx-64 px-20">

        <div className=" lg:col-span-2 lg:p-5  my-10">
          <TextAtom textStyle="text-gray-700 font-semibold" text="OUR CURRENT PROJECTS" />
          <TextAtom divStyle="pb-5 lg:my-0" textStyle="font-bold lg:text-4xl text-2xl font-spartan leading-tight" text="Software &amp; Mobile Application" />
          <TextAtom divStyle="my-4 lg:my-0 text-justify" textStyle="font-nunito_Sans text-gray-800" text="Whether your business needs a website, eCommerce website, a Mobile / Web Application, Game , AR & VR or any other services; Illusion Spot Line will provide you excellent customer service, expert marketing insight, and team is a diverse network of consultants and industry professionals with a global mindset and a collaborative culture. We work to understand your issues and are driven to ask better questions in the pursuit of making your business work better." />
          <div className="grid lg:grid-cols-2">
            {list.map((item) => (
              <div className="flex items-center mt-2">
                <AiOutlineCheckCircle className="text-primary w-4 h-4" />
                <TextAtom textStyle="px-2 text-sm truncate text-gray-700" text={item.text} />
              </div>
            ))}
          </div>
        </div>
        {BsCardList.map(item => (
          <CurrentProjectCard textStyle="text-2xl font-bold " img={item.img} text={item.text} />
        ))}
      </div>
      <SliderComp />


      {/* --------------- SLIDER ---------------  */}

      {/* <div className=" lg:mb-10 my-2  grid lg:grid-cols-4 gap-3 md:grid-cols-2  lg:max-w-screen-xl">

        {BsCardList.map(item => (
          <CurrentProjectCard textStyle="text-2xl font-bold " img={item.img} text={item.text} />
        ))}


        
      </div> */}


      {/* --------------- SLIDER ---------------  */}





      <TextWithRightArrowIcon handleOpenModal={() => handleOpenModal()} text="Let's Contact With Us" />
    </div>
  </div >
}

export { CurrentProjects };
