import React,{useState, useEffect, useRef} from 'react';
import {AiFillDownSquare, AiOutlineClose} from "react-icons/ai";
import {FiMenu} from "react-icons/fi";
import {ImageAtom, TextAtom} from "../view/atoms";
import {NavLink} from 'react-router-dom'
import {FaSearch} from "react-icons/fa";
import AddressSocialLink from "./AddressSocialLink";
import {BsChevronDown} from "react-icons/bs";

function NavigationMobile({styles}) {
 //const [currentScrollValue, setCurrentScrollValue] = useState(0);
  const currentScrollValue = useRef(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
  const [serviceOpen, setServiceOpen] = useState(false);
    const [serviceText, setServiceText] = useState(false);

  const handleScrollEvent = (e) => {
   // console.log(e.currentTarget.scrollY);

    currentScrollValue.current = e.currentTarget.scrollY;
    if (currentScrollValue.current > 50) {
      setDrawerOpen(false)
    }
  }

  const handleClose = (value) => {
       if (value) {
      setServiceText(true)
    } else {
      setServiceText(false)
   }
    setDrawerOpen(false);
    setServiceOpen(false);
  }

  const handleOpen = () => {
    setDrawerOpen(false)
    setServiceText(false)

  }

  useEffect(() => {

    window.addEventListener('scroll',handleScrollEvent)

  }, []);
  


  return <div className={`${styles} bg-white w-full`}>
    {drawerOpen ?   <div onClick={()=>setDrawerOpen(false)} className="py-2 px-4 flex items-center justify-end">
    <AiOutlineClose className="w-8 h-8 text-orange-600" />
 
    </div>:  <div onClick={()=>setDrawerOpen(true)} className="py-2 px-4 flex items-center">
    <FiMenu className="w-8 h-8 text-orange-600" />
        {/* <TextAtom textStyle="text-3xl font-semibold pl-4" text="Illusion Spot Line" /> */}
          <div className="flex justify-center mx-auto">
          <ImageAtom
            divStyle="mx-auto"
            imageStyle="h-12"
            img="https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/logo.png?alt=media&token=f684ff19-c320-487f-b32f-d4207958e509"
          />
</div>
    </div> }
 
    {drawerOpen && <div className="h-screen">
      <div className="flex-col  items-center bg-white w-full justify-center py-3">
        
        {/* <TextAtom divStyle="flex justify-center py-2" textStyle="text-3xl hover:text-orange-600 font-semibold pl-4" text="Illusion Spot Line" /> */}
        <div className="flex  justify-center mb-5">
         <ImageAtom
            imageStyle="h-12"
            img="https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/logo.png?alt=media&token=f684ff19-c320-487f-b32f-d4207958e509"
          />
</div>
      <NavLink exact onClick={()=>handleOpen(false)}  to="/"   className="flex items-center justify-center  text-lg px-4 py-1" >
         
        HOME
      </NavLink>
          <NavLink to="/about"  onClick={()=>handleOpen(false)} className="flex items-center justify-center text-xl  px-4 py-1"  >
        ABOUT
        </NavLink>
        <div className="flex justify-center items-center">
           <div onClick={()=>setServiceOpen(state=>!state)} className={serviceText? "flex items-center text-primary justify-center text-xl px-4 py-1":"flex items-center justify-center text-xl px-4 py-1" }  >
        SERVICES
        </div>
          <BsChevronDown className={serviceOpen ? "rotate-180 duration-300" : "rotate-0 duration-300"} />
        </div>
       {serviceOpen ? <div  className="bg-white transform duration-1000">
            <NavLink to="/branding" onClick={()=>handleClose(true)} activeClassName="bg-gray-200"  className="flex items-center justify-center text-sm px-4 py-1 "  >
        BRANDING
        </NavLink>
            <NavLink to="/web_designing" onClick={()=>handleClose(true)} activeClassName="bg-gray-200"  className="flex items-center justify-center text-sm px-4 py-1"  >
        WEB DESIGNING
        </NavLink>
            <NavLink to="/mobile_apps" onClick={()=>handleClose(true)} activeClassName="bg-gray-200"  className="flex items-center justify-center text-sm px-4 py-1"  >
        MOBILE APPLICATION
        </NavLink>
            <NavLink to="/game" onClick={()=>handleClose(true)} activeClassName="bg-gray-200"  className="flex items-center justify-center text-sm px-4 py-1"  >
        GAME DEVELOPMENT
      </NavLink>
     
        </div> : null} 
           <NavLink to="/products" onClick={()=>handleClose()} activeClassName="bg-gray-200"  className="flex items-center justify-center text-xl px-4 py-1"  >
        PRODUCTS
      </NavLink>
           <NavLink to="/clients" onClick={()=>handleOpen(false)} className="flex items-center justify-center text-xl px-4 py-1"  >
        CLIENTS
      </NavLink>
           <NavLink to="/contact" onClick={()=>handleOpen(false)} className="flex items-center justify-center text-xl px-4 py-1"  >
        CONTACT
      </NavLink>
      {/* <div className="flex items-center justify-center py-5 px-4 ">
 <FaSearch/>
      </div> */}
     
  
      </div>
      <AddressSocialLink/>
    </div> }
  
     </div>
}

export default NavigationMobile;
