import React from 'react';
import { TextAtom } from "../atoms";
import banner_01 from "../../assets/images/products/banner_01.jpg";

function HomePageImage() {
  return (<>

    {/* <div className=" object-cover h-screen " style={{
      backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/homeBanner.jpg?alt=media&token=472fa759-2044-47c3-85c8-027275c505c6")`
    }} > */}


    {/* <div className="lg:pt-96 md:pt-144 pt-96 lg:ml-80 md:ml-20 ml-5">
        <TextAtom textStyle="lg:text-8xl font-spartan md:text-6xl text-5xl text-white leading-8 font-bold" text="Your Vision." />
        <TextAtom textStyle="lg:text-8xl font-spartan md:text-6xl text-5xl text-white leading-tight font-bold" text="Our Passion." />
        <TextAtom divStyle="my-3 mr-5 leading-6" textStyle="md:text-3xl text-white text-2xl" text="Illusion Spot Line expertise that helps your business thrive." />
      </div>
    </div> */}

    <div className=" md:pt-0 pt-20">

      <img src={banner_01} />
    </div>

  </>
  )

}

export { HomePageImage }
