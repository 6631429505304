import React from 'react';
import {InputText,TextAtom} from "../view/atoms";
import {InputWithText} from "../view/molecules/InputWithText";
import emailjs from '@emailjs/browser';

const optionList = [
  
    ' Art & Design',
    'Banking',
    'Construction & Real Estate',
    'Consumer Goods',
    'Corporate Services',
    'Capital Markets',
    'Energy, Mining & Utilities',
    'Education',
    'Entertainment',
    'Financial Services',
    'Government & Public Services',
    'Hardware & Telecom',
    'Healthcare',
    'Insurance',
    'Legal',
    'Manufacturing',
    'Media & Communications',
    'Non-profit',
    'Online & Ecommerce',
    'pharma',
    'Retail',
    'Software & IT Services',
    'Travel, Transport & Logistics',
    'Others',
    'Hospitality',
    'Market Research',
    'Analyst',
    'Staffing & Recruitment',
    'Publishing',



]

function ContactForm({divStyle}) {
  const form = React.useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_x7wqaei', 'template_ti8szv9', form.current, 'user_BZhRna8AIoWD2jPAVd4g0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset();
  };

  return     <form ref={form} onSubmit={sendEmail} className={`${divStyle}`}> <div className={` lg:mx-0 mx-5 flex-col justify-center lg:grid lg:grid-cols-2 gap-2  `}>
    <InputWithText type="text" name="first_name" text="First Name" placeholder="First Name" />
    <InputWithText type="text" name="last_name" text="Last Name" placeholder="Last Name" />
    <InputWithText type="email" name="email" text="Email" placeholder="Email" />
    <InputWithText type="number" name="phone_no" text="Phone No" placeholder="Phone No" />
    <InputWithText type="text" name="company_name" text="Company Name" placeholder="Company Name" />
    <div>
     <TextAtom textStyle="font-semibold" text="Industry"/>
        <select name="industry" className="text-sm font-semibold border-1 border-gray-500 outline-none text-gray-400 w-full my-2 py-1 rounded-md bg-gray-100" >
          {optionList.map(item => (
             <option className="text-sm  text-gray-800 font-semibold w-full">

            {item}
          </option>
          ))}
         
      </select>
    </div>
    <div className="col-span-2 ">
      <TextAtom textStyle="font-semibold" text="Message"/>
      <textarea name="message" className=" border-1 outline-none my-1  w-full border-gray-500 px-2 bg-gray-100 rounded-md h-24 text-gray-700 font-normal p-1" placeholder="Your Message" />
      </div>
  </div>

    <div className="flex justify-end pr-5 lg:pr-0  md:mr-0 md:mt-10 pb-4">
      <input className="bg-primary p-2 rounded-md text-white font-nunito_Sans cursor-pointer" type="submit" value="GET IN TOUCH" />
    </div>
  </form>
   
}

export {ContactForm};
