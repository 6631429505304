import BannerSlider from "../../components/banner_slider";
import Slider from "react-slick";
import "../../components/banner_slider/style.css";

import { BorderWithText } from "../molecules";

function SlidingData({list, borderText, slidesToShow}) {
     const settings = {
    infinite: true,
    lazyload: true,
    arrows: false,
    speed: 1500,
    slidesToShow: slidesToShow,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    adaptiveHeight: true,
    variableWidth: true
    
  };
  return (
           <div className="py-2">
      <BorderWithText text={borderText} />
      <div className="mt-6">
          <>
      <Slider  adaptiveHeight={true} {...settings}>
        {list.map((img) => (
          <div className=" flex items-center" key={img}>
            {/* <img className={isMobileView ? "bg-cover" : text === "T E C H N O L O G I E S" ?  " h-20 mt-2": " h-40 mt-2"} src={img} alt="isl" /> */}
            <div className={ "lg:w-64 lg:h-32 w-32 h-20 bg-contain"} style={{backgroundImage:`url('${img}')`,backgroundRepeat:'no-repeat'}}/>
          </div>
        ))}
      </Slider>
    </>
      </div>
    </div>
  );
}

export { SlidingData };
