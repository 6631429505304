import React from 'react';
import {TextAtom} from "../atoms";
import { FiArrowRightCircle } from "react-icons/fi";

function TextWithRightArrowIcon({text, handleOpenModal}) {
  return <div onClick={() => handleOpenModal()} className="flex  cursor-pointer justify-center rounded-lg  items-center py-4">
    <div className="flex items-center hover:bg-gray-200 hover:shadow-sm rounded-md px-3">
    <TextAtom  textStyle="text-sm text-orange-600 font-semibold  " text={text} />
      <FiArrowRightCircle className="mx-2 text-orange-600  w-6 h-6 pl-2" />
      </div>
  </div>;
}

export {TextWithRightArrowIcon};
