import React from 'react';
import {GoMail} from "react-icons/go";
import {ImageAtom, InputText, TextAtom} from "../atoms";
import {ContactForm} from '../../components/ContactForm'

function ContactTabTwo() {
  return <div className="bg-blue-500 "> <div className="flex justify-center lg:rounded-bl-25xl  bg-white lg:py-10 ">
    <div className="lg:flex justify-center py-5 w-full lg:max-w-screen-xl">
  
     

      <div className="lg:w-6/12 lg:mx-0 mx-5  flex justify-center items-center">
        <TextAtom divStyle="flex md:justify-start justify-center items-center " textStyle="lg:text-5xl font-spartan md:text-3xl text-xl md:text-left text-center "  text="Lets Take Our Conversation Ahead"/>


      </div>
     <ContactForm divStyle="lg:w-6/12"/>




  </div> 
  </div>
    </div>
}

export {ContactTabTwo};
