import React,{useState} from 'react';
import { FaSearch } from "react-icons/fa";

function SearchMolecule() {
  const [inputFocus, setInputFocus] = useState(false);
  const [text, setText] = useState('');

  const handleSearch = () => {
    setInputFocus(true)
  }

  function handleChange(event) {
    console.log(event.target.value);
    setText(event.target.value)
  }
  const handleSearchClose = () => {
    console.log(text.length);
    if (0 === text.length) {
      
      setInputFocus(false)
    }else {
     setInputFocus(true)
  }
  }

  React.useEffect(() => {
 console.log(text);
  }, [text]);
  
  return  inputFocus ? <div onMouseLeave={handleSearchClose} className="flex items-center border-1 rounded-md  px-2 py-1">
   
    <input className="outline-none pl-2 pr-4" />
     <FaSearch />
      </div>: <div className="flex items-center px-2 py-1">
    <FaSearch onClick={()=>handleSearch()}/>
    <input onChange={handleChange} className="outline-none pl-4 pr-2 invisible"/>
      </div>
}

export {SearchMolecule};
