import React,{useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {ImageAtom, TextAtom} from "../view/atoms";
import {SearchMolecule} from "../view/molecules/SearchMolecule";



function NavigationDesktop({styles}) {
  const [navLink, setNavLink] = useState(false);
  const [serviceText, setServiceText] = useState(false);
  const handleLinkClose = (value) => {

    if (value) {
      setServiceText(true)
    } else {
      setServiceText(false)
   }

    setNavLink(false)
  }
  const handleLinkOpen = () => {
    setNavLink(state => !state)
    
  }


  return <div className={`${styles}`}>
    {/* <TextAtom textStyle="text-3xl hover:text-orange-600 font-semibold pl-4" text="Illusion Spot Line" /> */}
      <ImageAtom
            imageStyle="h-12"
            img="https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/logo.png?alt=media&token=f684ff19-c320-487f-b32f-d4207958e509"
          />
    <div className="flex ">
      <NavLink exact onClick={()=>handleLinkClose()} activeClassName="text-green-500 px-10" to="/"   className="flex items-center hover:shadow-lg md:px-2 lg:px-4 py-1" >
       Home
      </NavLink>
          <NavLink to="/about" onClick={()=>handleLinkClose()}  className="flex items-center hover:shadow-lg  md:px-2 lg:px-4 py-1"  >
        About
      </NavLink>
      <div onClick={() => handleLinkOpen()} className={ serviceText ? "flex items-center text-primary hover:shadow-lg md:px-2 lg:px-4 py-1 cursor-pointer":"flex items-center hover:shadow-lg md:px-2 lg:px-4 py-1 cursor-pointer"}  >
        Services
      </div>
      
     {navLink ? <div className="bg-white absolute ml-40 shadow-lg mt-8 px-2">
            <NavLink onClick={()=>handleLinkClose(true)} to="/branding"  className="flex items-center hover:shadow-lg  md:px-2 lg:px-4 py-1"  >
        Branding
        </NavLink>
            <NavLink onClick={()=>handleLinkClose(true)} to="/web_designing"  className="flex items-center hover:shadow-lg  md:px-2 lg:px-4 py-1"  >
        Web Designing
        </NavLink>
            <NavLink onClick={()=>handleLinkClose(true)} to="/mobile_apps"  className="flex items-center hover:shadow-lg  md:px-2 lg:px-4 py-1"  >
        Mobile Application
        </NavLink>
            <NavLink onClick={()=>handleLinkClose(true)} to="/game"  className="flex items-center  hover:shadow-lg md:px-2 lg:px-4 py-1"  >
        Game Development
      </NavLink>
     
      </div> : null} 
          <NavLink to="/products" onClick={()=>handleLinkClose()}  className="flex items-center hover:shadow-lg  md:px-2 lg:px-4 py-1"  >
        Products
      </NavLink>
           <NavLink to="/Clients" onClick={()=>handleLinkClose()}  className="flex items-center hover:shadow-lg md:px-2 lg:px-4 py-1"  >
        Clients
      </NavLink>
           <NavLink to="/contact" onClick={()=>handleLinkClose()}  className="flex items-center hover:shadow-lg md:px-2 lg:px-4 py-1"  >
        Contact
      </NavLink>
    <SearchMolecule/>
     
    </div>
  </div>;
}

export  {NavigationDesktop};
