import React from 'react';
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import {AboutUsTabOne, AboutUsTabThree, AboutUsTabTwo} from "../organisms";

function AboutTemplate() {

  const [open, setOpen] = React.useState(false);
  
  const handleOpenModal = () => {
    setOpen(state=>!state)
  }

  return (
    <>
      <Modal modalOpen={open} handleOpenModal={handleOpenModal}/>
    <AboutUsTabOne />
    <AboutUsTabTwo />
    <AboutUsTabThree handleOpenModal={handleOpenModal} />
    <Footer/>
  </>
  )
}

export {AboutTemplate};
