import React from 'react';
import {TextAtom} from "../view/atoms";
import {AiOutlineClose} from "react-icons/ai";
import {ContactForm} from "./ContactForm";

function Modal({modalOpen, handleOpenModal}) {
  return modalOpen ? <div className="fixed z-50 lg:-mt-10 -mt-14 w-full bg-gray-200 lg:h-screen lg:pt-32 flex justify-center">
        
        <div className=" bg-white md:h-screen m-5 w-full mt-12 rounded-md max-w-screen-lg lg:h-144 border-1  shadow-sm drop-shadow-sm">
          <div className="my-3 mx-5 flex justify-between">
            <TextAtom textStyle="text-xl font-semibold" text="Contact Us" />
            <AiOutlineClose onClick={()=>handleOpenModal()} className="w-6  h-6 cursor-pointer" />
          </div>
          <hr/>
      <div className="lg:flex md:justify-between md:w-full md:p-2"> 
        <div className="lg:flex lg:w-6/12 lg:mt-0 lg:h-128 w-0 h-0 lg:justify-center md:items-center">
          <div className={" w-full md:mt-20 md:ml-10 md:rounded-md md:h-128"} style={{backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/contactus.jpg?alt=media&token=35012f1a-a52e-4e60-90ee-a4cf458813c9')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}></div>
          </div>
        <ContactForm divStyle="rounded-lg" />
        </div>
             </div> 
         
      </div> : null
}

export default Modal;
