import React from 'react';
import {TextAtom} from "../atoms";

function BorderWithText({text}) {
  return <div>
    <hr className="mt-5" />
    <div className="-mt-4 flex justify-center ">
      <TextAtom textStyle="bg-white px-4 text-xl text-gray-700 rounded-md" text={text}/>
    </div>
    
  </div>;
}

export {BorderWithText};
