import React,{useState} from 'react';
import {TextAtom} from "../view/atoms";
import {ProductListDital} from "../view/molecules";

function ProductCard({list, img, title}) {
  const [image, setImage] = useState(img);

  const handleImageChange = (img) => {
  
  setImage(img)
  }

  return <div className="md:mt-10  bg-gray-200 p-5 rounded-xl mx-2 mt-5 md:mx-5">
    <TextAtom divStyle="lg:pb-5 " textStyle="text-2xl text-gray-800 font-spartan font-semibold" text={title}/>
    <div className="lg:flex">
      <div className="lg:w-3/12 md:flex lg:flex-row md:justify-center">
      <div className="lg:w-68 lg:h-128 md:w-80 h-144 border-8 rounded-3xl border-gray-800" style={{backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
      </div>
      <div className="grid md:grid-cols-2 ">
        <ProductListDital handleImageChange={handleImageChange} list={list} />
        </div>
  </div>
  </div>


}

export default ProductCard;
