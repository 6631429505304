import React from 'react';
import {ImageAtom, TextAtom} from "../atoms";

import about from '../../assets/images/about.png'

function AboutUsTabOne() {
  return <div className="flex justify-center items-center bg-gray-200">
    <div className="md:m-10 lg:flex w-12/12 lg:max-w-screen-xl">
    <ImageAtom divStyle="lg:w-6/12 lg:mt-0 mt-10" img={about} />
      <div className="lg:w-6/12 p-12">


          <TextAtom textStyle="text-primary font-semibold" text="ABOUT US"/>
      <TextAtom divStyle="my-4 lg:my-0" textStyle="font-bold lg:text-4xl text-2xl  my-5 leading-tight  " text="Illusion Spot Line"/>
      <TextAtom textStyle="font-nunito_Sans text-gray-800" divStyle="my-4 text-justify" text="Since 2011, Illusion Spot Line has been the ‘go-to’ Custom Graphics Studio, trusted by some of the world’s top brands. Illusions Spot line delivers on Time and on Budget. We are Responsive, Friendly, and go the extra mile. We are here for all you needs."/>
      <TextAtom textStyle="font-nunito_Sans text-gray-800" divStyle="my-4 text-justify" text="We have several years’ experience working with Web Design, Mobile Application, 3D & 2D Game Development, Architects visualization & Product visualization that make us supreme in every field. With skilled and experienced team we always complete our commitment with client’s satisfaction."/>
      <TextAtom textStyle=" text-gray-800 font-nunito_Sans" divStyle="my-4 text-justify" text="We create value for our clients by helping them to create value for their customers."/>
      <TextAtom textStyle="text-gray-800 font-nunito_Sans" divStyle="my-4 text-justify" text="Digitization is transforming every business into a technology business, with boundless opportunities all along the value chain. To benefit from these opportunities, you have to move fast or risk losing the first-mover advantage. From our headquarters in Minneapolis, we help companies tap into the technology expertise and operational efficiencies made possible by our global- delivery model—and always go for the gold."/>
    </div>
  </div>
    </div>
}

export {AboutUsTabOne};
