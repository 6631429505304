import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import React, {useEffect, useState} from 'react';



import Home from './page/Home';
import About from './page/About';
import Clients from './page/Clients';
import AddressSocialLink from "./components/AddressSocialLink";
import {NavigationDesktop} from "./components/NavigationDesktop";
import NavigationMobile from "./components/NavigationMobile";
import Contact from "./page/Contact";
import Branding from "./page/Branding";
import WebDesigning from "./page/WebDesigning";
import MobileApps from "./page/MobileApps";
import GameDevelopment from "./page/GameDevelopment";
import Products from "./page/Products";
import NotFound from "./page/NotFound";



function App() {


  const [isMobile, setIsMobile] = useState(true);
  //const [scrollTabValue, setScrollTabValue] = useState(0);
  const prevScrollPosRef = React.useRef(0);
  const [fixed, setFixed] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    window.addEventListener("resize", handleResize);

  }, [isMobile]);

  useEffect(() => {
    const listenToScroll = (e) => {
      console.log(e);
      // const window = e.currentTarget;
      // console.log(prevScrollPosRef.current,'old');
      //console.log(window.scrollY,'new');
      if (prevScrollPosRef.current > window.scrollY) {
        if (window.scrollY < 100) {
          console.log('if');
          setFixed(false);
        } else {
          console.log(window.scrollY);
          setFixed(true);
        }

      } else {

        setFixed(false);
      }
      prevScrollPosRef.current = window.scrollY;
    };
    console.log(fixed, 'fixed');
    window.addEventListener("scroll", listenToScroll);

    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, [fixed]);

  console.log(fixed);

  return (
    <Router>
      {isMobile !== null && isMobile ? <div>
        {fixed ? <NavigationMobile styles="fixed z-50" /> : <NavigationMobile styles="absolute" />}
      </div> : <div>
        <AddressSocialLink />
        {fixed ? <NavigationDesktop styles="flex items-center bg-white w-full z-50 fixed -mt-14 justify-around pt-4" /> : <NavigationDesktop styles="flex items-center bg-white w-full absolute justify-around py-3" />}


      </div>}
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/branding" element={<Branding />} />
          <Route path="/web_designing" element={<WebDesigning />} />
          <Route path="/mobile_apps" element={<MobileApps />} />
          <Route path="/game" element={<GameDevelopment />} />
          <Route path="/products" element={<Products />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </div>
    </Router>
  );
}
export default App;
