import React from 'react';
import {BrandingCard} from "../../components/BrandingCard";
import {TextWithRightArrowIcon} from "../molecules";

import teamwork from '../../assets/images/teamwork.png'
import creativity from '../../assets/images/creativity.png'
import service from '../../assets/images/service.png'
import philosophy from '../../assets/images/philosophy.png'

const list = [
  {
    img:teamwork,
    title: "Connected Experiences",
    para:"Brand experiences focus as much on the connections between touch points as the touch points themselves.Brand experience strategy and design optimists the journey around customer needs and goals across touch points and channels creating a connected brand experience."
  }, {
    img:creativity,
    title: 'Design & Creative',
    para:"Brand experiences focus as much on the connections between touch points as the touch points themselves.Brand experience strategy and design optimists the journey around customer needs and goals across touch points and channels creating a connected brand experience."
  },
  {
    img:service,
    title: "Product & Service",
    para:"We bring together customer experience designers and industrial designers to rapidly prototype and deliver products and services that deliver on customer and business needs. Our capabilities include category and consumer insights, customer journey mapping, prototyping, business case development, industrial design and manufacturing, service design and delivery."
  }, {
    img:philosophy,
    title: "Our Philosophy",
    para:"We are a leader in innovation, quality, efficiency and customer satisfaction. We operate with complete integrity, focus entirely on the business needs of our customers and treat our target audiences with the dignity and respect they rightly deserve. Our future will be determined by our ability to satisfy our employees and provide our clients with great value by producing the finest products backed by consistently top-quality service."
  }
]

function BrandingTabTwo({handleOpenModal}) {
  return <div className="bg-blue-500 ">
    
    <div className="flex justify-center bg-white rounded-tr-6xl rounded-bl-6xl py-20">
      <div className="">
    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5 mx-5 lg:mx-0  max-w-screen-xl pb-2 lg:pb-10">
      {list.map((item) => (
        <BrandingCard img={item.img} title={item.title} para={ item.para}/>
      ))}
     
    </div>
      <TextWithRightArrowIcon handleOpenModal={handleOpenModal} text="Contact Us" />
    </div>
      </div>
    </div>
}

export default BrandingTabTwo;
