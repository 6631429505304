import React from 'react';
import { TextAtom } from '../atoms'
import { ClientCard } from "../../components/ClientCard";
import Footer from "../../components/Footer";
import { TextWithRightArrowIcon } from "../molecules";
import Modal from "../../components/Modal";
import ProductCard from "../../components/ProductCard";

import cmmobile from '../../assets/images/cmmobile.png'
import payment from '../../assets/images/payment.png'
import inventory from '../../assets/images/inventory.png'
import location from '../../assets/images/location.png'
import delivery from '../../assets/images/delivery.png'
import food from '../../assets/images/food.png'


import angle from '../../assets/images/angle.png'
import custmizingangle from '../../assets/images/custmizingangle.png'
import chat from '../../assets/images/chat.png'

import artificali from '../../assets/images/artificali.png'
import multiplayer from '../../assets/images/multiplayer.png'
import categories from '../../assets/images/categories.png'
import levelgame from '../../assets/images/levelgame.png'
import leader from '../../assets/images/leader.png'







const list = [



  {
    title: "Playgroup 1",
    expertise: [
      {
        title: "One Place For Everything",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/playgroup-1.jpg?alt=media&token=c33e30d8-c06c-4857-93c2-f376911bb703",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/playgroup-2.jpg?alt=media&token=9a7f2dcd-d910-4155-9909-0b8fe33ee879",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/playgroup-3.jpg?alt=media&token=42843700-3567-44e0-bfe4-07bd02fb9cbf",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/playgroup-4.jpg?alt=media&token=1902828e-ac1c-4634-8949-5f47050dd2fe",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/playgroup-1.jpg?alt=media&token=c33e30d8-c06c-4857-93c2-f376911bb703",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/playgroup-2.jpg?alt=media&token=9a7f2dcd-d910-4155-9909-0b8fe33ee879",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },

    ]
  },



  {
    title: "VocaBuilder Toto",
    expertise: [
      {
        title: "One Place For Everything",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-toto-1.png?alt=media&token=7dac2ae9-94f3-4dd7-9ed5-6e1b43142646",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-toto-2.png?alt=media&token=151783c1-c9b4-45b9-ac3c-80c894fc609d",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-toto-3.png?alt=media&token=6aec6308-595b-483e-a5b9-16b8cd483f83",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-toto-4.png?alt=media&token=b249717b-d83a-4949-81ec-49b28fe01694",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-toto-5.png?alt=media&token=d82fdf22-1eb7-46dc-9719-7b960b6fe1c9",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-toto-6.png?alt=media&token=fe0053ad-2ad0-4564-934b-12db449581f6",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },

    ]
  },



  {
    title: "VocaBuilder Duo",
    expertise: [
      {
        title: "One Place For Everything",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-duo-1.png?alt=media&token=04801895-14e0-460d-9584-0ae962abedaf",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-duo-2.png?alt=media&token=3abd2c3c-1235-423f-b6aa-959191704a34",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-duo-3.png?alt=media&token=bccc4942-55be-45bf-90fd-2d06f5e2389f",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-duo-4.png?alt=media&token=0a1d1f1c-d1c2-4edd-8bec-6e36e64870f1",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-duo-5.png?alt=media&token=a292cc1e-4ad4-4fee-8a7c-d36dc90b8ba7",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-duo-6.png?alt=media&token=41719f60-6428-4ab3-bb08-a22d42f7b5fe",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },

    ]
  },



  {
    title: "VocaBuilder Solo",
    expertise: [
      {
        title: "One Place For Everything",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-solo-1.png?alt=media&token=b9b5ba90-7c91-43b4-9105-ddb679348895",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-solo-2.png?alt=media&token=74c926cb-c5ad-4709-96ac-3e74ece690ad",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-solo-3.png?alt=media&token=0a896ded-8550-4240-b1be-33087c219c5f",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-solo-4.png?alt=media&token=7f48a997-67f2-4a9f-923d-005090614ab0",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-solo-5.png?alt=media&token=adbeb732-aa81-4596-8221-01e04917ffe6",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vocabuilder-solo-6.png?alt=media&token=8e31695d-d2e3-4935-8131-bc3df53ccafc",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },

    ]
  },



  {
    title: "Starpot",
    expertise: [
      {
        title: "One Place For Everything",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/starpot-1.png?alt=media&token=0660d4ff-707c-4163-8823-2387c1e45d25",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/starpot-2.png?alt=media&token=a2109fc0-9db0-44c5-88a4-699f535b09eb",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/starpot-3.png?alt=media&token=d06e7db0-82a3-440a-a936-00642ca3a8ab",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/starpot-4.png?alt=media&token=a3d2f755-c245-447d-a007-3063426253a0",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/starpot-5.png?alt=media&token=b4894307-5b77-4c91-96ce-19f011aedbb0",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/starpot-6.png?alt=media&token=fa9bea3b-740a-432b-9cea-8c293b41e160",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },

    ]
  },



  {
    title: "KGPrep 3",
    expertise: [
      {
        title: "One Place For Everything",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep3-1.jpg?alt=media&token=aed02e56-5187-4c9e-86ce-c383b843da6c",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep3-2.jpg?alt=media&token=d8e135e6-a245-4d4b-92d6-eb4ad439e7ac",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep3-3.jpg?alt=media&token=686c9856-57dc-471c-a31d-2311dec7378f",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep3-4.jpg?alt=media&token=c24139d4-1b53-40b1-8a4d-7de1daf30c34",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep3-5.jpg?alt=media&token=9938ac1a-54ba-4ecc-9013-4221d61188f7",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep3-6.jpg?alt=media&token=0dbb6c23-f8da-4d50-800d-aeee0aa810f9",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },

    ]
  },



  {
    title: "KGPrep 2",
    expertise: [
      {
        title: "One Place For Everything",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep2-1.jpg?alt=media&token=898eb02f-1345-4314-9be8-5c70718af424",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep2-2.jpg?alt=media&token=ec6f677a-45d3-411b-91db-3c69d85958ec",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep2-3.jpg?alt=media&token=339eb570-dee2-4723-a3af-e178668c8a4d",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep2-4.jpg?alt=media&token=1c145924-8aa2-491e-9411-1862cc20dd71",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep2-5.jpg?alt=media&token=ee82fb53-961d-4513-bc38-18ae5cfb907a",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/kgPrep2-6.jpg?alt=media&token=c0ad79eb-ba19-4f41-b00c-15c48fc692e5",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },

    ]
  },




  {
    title: "Rite Angle",
    expertise: [



      {
        title: "One Place For Everything",
        screenShot: "https://play-lh.googleusercontent.com/IjqL2eCzzlAbvYST2yqF_Ag5wsEF5j5fPi5TIfGFh1LcGWgREbPlsQb4-J6BNvsrcDXW=w1920-h880-rw",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://play-lh.googleusercontent.com/XOnFkUbDBHal--6RL9JJOlaiU-FixH_AKUYTm8diu6xtztoqUqcKO91e7loDwBUS_Q=w1920-h880-rw",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://play-lh.googleusercontent.com/M0d4Y6nwix0AlDGNb4BrqdrieCA3MFNBGPR-glFAEKe2FE4MsK6-FU0jWBzawAJWcA=w1920-h880-rw",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://play-lh.googleusercontent.com/juf98yuRk7ksJcH_SgbEQDBF_pEUIbyI4-DoVeSj8gwbQA4HenHU_e-hxyhfDDq9qg=w1920-h880-rw",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://play-lh.googleusercontent.com/XOnFkUbDBHal--6RL9JJOlaiU-FixH_AKUYTm8diu6xtztoqUqcKO91e7loDwBUS_Q=w1920-h880-rw",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://play-lh.googleusercontent.com/IjqL2eCzzlAbvYST2yqF_Ag5wsEF5j5fPi5TIfGFh1LcGWgREbPlsQb4-J6BNvsrcDXW=w1920-h880-rw",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },


    ]
  },



  {
    title: "Planext4u",
    expertise: [
      {
        title: "One Place For Everything",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/p4u-SplashScreen.png?alt=media&token=52c21c38-859d-46ad-a961-58eb96c6eb92",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Salon",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/p4u-Intro1.png?alt=media&token=9ead2bbc-4159-41ba-b1cb-3a4a524d505e",
        img: artificali,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Global Multiplayer",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/p4u-Intro2.png?alt=media&token=745fb7ab-60c4-4c00-91a1-c6c6992caf0d",
        img: multiplayer,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "12 Categories",
        screenShot: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/p4u-Intro3.png?alt=media&token=4bdff70b-d88d-4568-ac20-c90f9d9824dd",
        img: categories,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "More Levels",
        screenShot: "https://play-lh.googleusercontent.com/kINBZ7lywtfeE4uCZWyZUll5HUJtFM1JGcSzLyA68KVpolyFoLyIOGUoTz4AVL8mXA=w1920-h880-rw",
        img: levelgame,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Leader Board",
        screenShot: "https://play-lh.googleusercontent.com/joUCPpvVT4hXUr0CtOJ8tfaVoEWqQgL1I27WJEnZnYvzS42RhKYQ7sChrKnocpWDRg=w1920-h880-rw",
        img: leader,
        para: "Let your customers mark their delivery address precisely using maps"
      },

    ]
  },



  {
    title: "Food App",
    expertise: [
      {
        title: "Dynamic homepage",
        screenShot: "https://manyandroid.app/wp-content/uploads/2020/04/take-screenshot-tecno-mobile-phone-smart-panel.jpg",
        img: cmmobile,
        para: "Customize your home page with attractive banners, news, Best sellers and Fast moving sections and more."
      },
      {
        title: "Payment Modes",
        screenShot: "https://www.devicesfaq.com/img/screenshot/home-android-en.jpg",
        img: payment,
        para: "Accept payments from your customers through flexible payment modes"
      },
      {
        title: "Real-time inventory",
        screenShot: "https://www.devicesfaq.com/img/screenshot/home-android-en.jpg",
        img: inventory,
        para: "Provide the same retail offline shopping experience by displaying the stock availability of the products"
      },
      {
        title: "Geo location tagging",
        screenShot: "https://manyandroid.app/wp-content/uploads/2020/04/take-screenshot-tecno-mobile-phone-smart-panel.jpg",
        img: location,
        para: "Let your customers mark their delivery address precisely using maps"
      },
      {
        title: "Pick up/Delivery slots",
        screenShot: "https://manyandroid.app/wp-content/uploads/2020/04/take-screenshot-tecno-mobile-phone-smart-panel.jpg",
        img: delivery,
        para: "Allow your customers to choose their preferred timings for Pick up and Delivery"
      },
      {
        title: "Real-time order updates",
        screenShot: "https://manyandroid.app/wp-content/uploads/2020/04/take-screenshot-tecno-mobile-phone-smart-panel.jpg",
        img: food,
        para: "Send real-time order status updates through push notifications"
      },


    ]
  },


]

function ProductsTemplate() {
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = () => {
    console.log('click');
    setOpen(state => !state)
  }
  return <>
    <Modal modalOpen={open} handleOpenModal={handleOpenModal} /><div className="flex justify-center items-center lg:p-5">
      <div className=" w-full flex justify-center bg-gradient-to-t from-white via-white to-gray-200 ">
        <div className="py-10 mt-12 lg:mt-10">
          <div className="max-w-screen-xl ">
            <TextAtom divStyle="flex justify-center" textStyle="font-Spartan_semiBold  lg:text-5xl md:text-3xl text-2xl my-5 leading-tight" text="Our Products" />
            {/* <TextAtom divStyle="flex justify-center lg:pb-5" textStyle="lg:text-2xl text-xl text-center font-gray-800  md:my-5 leading-tight" text="We explore some of the latest trends and strategies" /> */}

            <div className="lg:w-12/12 mt-10">
              {list.map(item => (
                <ProductCard title={item.title} img={item.expertise[0].screenShot} list={item.expertise} />
              ))}
            </div>
          </div>
          <TextWithRightArrowIcon handleOpenModal={handleOpenModal} text="Get in Touch" />
        </div>
      </div>
    </div>
    <Footer />
  </>;
}

export { ProductsTemplate };
