import React from 'react';
import {HomeTemplate} from "../view/templates";

function Home() {
  return <div className="">
    <HomeTemplate/>
  </div>;
}

export default Home;
