import React from 'react'
import Footer from "../../components/Footer";
import OurExpertise from "../../components/OurExpertise";
import Technologies from "../../components/Technologies";

import { SlidingData, CurrentProjects, HomePageImage } from "../organisms";

import { useIsMobileViewContext } from "../../context/isMobileView";
import {ContactForm} from "../../components/ContactForm";
import {TextAtom} from "../atoms";
import Modal from "../../components/Modal";

import react from '../../assets/images/react.png'
import pwa from '../../assets/images/pwa.png'
import angular from '../../assets/images/angular.png'
import python from '../../assets/images/python.png'
import ruby from '../../assets/images/ruby.png'
import appword from '../../assets/images/appword.png'
import aws from '../../assets/images/aws.png'
import threedot from '../../assets/images/threedot.png'
import node from '../../assets/images/node.png'
// import react from '../../assets/images/react.png'
// import react from '../../assets/images/react.png'
// import react from '../../assets/images/react.png'
// import react from '../../assets/images/react.png'


const list = [
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ah.jpg?alt=media&token=2563828a-d434-42ef-a475-13e108ca0116",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/babilav.jpg?alt=media&token=bd746e60-a410-4763-81e6-305560248433",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/clb.jpg?alt=media&token=e3418b76-b462-41b9-93e8-86cda7befaaf",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/cm.jpg?alt=media&token=62a4d7f9-ac71-463e-8edf-22be33485356",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/cmm.jpg?alt=media&token=29497910-d85c-46d5-8eb8-e94474f1e0fe",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/fd.jpg?alt=media&token=63a08b74-30ab-4248-bf74-9dc1b4b3cca7",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/foxs.jpg?alt=media&token=4cf32689-28fc-4cc8-a2d9-0da8b735f050",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/lp.jpg?alt=media&token=7481b62e-7d6f-485b-98b7-9fa543802d87",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ls.jpg?alt=media&token=7633fc98-2a9e-44d1-8681-0c487f966a9d",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/miacincons.jpg?alt=media&token=f3e96380-1745-40f6-af63-63caa3ef820d",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/neroro.jpg?alt=media&token=835488cb-9c06-4cf7-a040-ec32339ec8d3",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/nl.jpg?alt=media&token=5a7d7014-3c5d-4eed-b330-b8e9d8cad6c4",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/notebook.jpg?alt=media&token=463c9f01-97c3-419d-a6a2-e50190ddde96",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/o.jpg?alt=media&token=21752b75-815b-40dd-a90e-7c3ddb5e5fbe",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/perali.jpg?alt=media&token=77b21efc-5847-4aa8-81b3-45799f05a528",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/plusm.jpg?alt=media&token=e31bc5b1-f90b-4cae-9e4d-787ecb9d4668",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/wwt.jpg?alt=media&token=8aa92759-bd3b-4b15-bc7e-034a5b428927",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/pne.jpg?alt=media&token=e4fc9ecb-f7ba-437e-8a0e-33dc80bfac61",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ps.jpg?alt=media&token=9bfec0c6-4fa5-43ff-9690-d74fecb71130",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/qkt.jpg?alt=media&token=9f36e64d-529a-4c6d-b57d-96ae99dcc84d",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/qtv.jpg?alt=media&token=0c98d9a9-0ae1-4187-9ce5-abb22fbc9cc1",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/sgp.jpg?alt=media&token=e8e2f3d1-041d-4ea7-8a10-805eb8991024",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/shs.jpg?alt=media&token=788c8d6a-a1bb-4da8-8ff3-af76e30d9d79",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ss.jpg?alt=media&token=bd7170de-9421-494c-b445-32f4bb00a9ed",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/traders.jpg?alt=media&token=30077a2d-6c18-4f5c-948e-a660891442e2",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vfocus.jpg?alt=media&token=ce803031-467e-468f-9401-560647e758f0",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/wwt.jpg?alt=media&token=8aa92759-bd3b-4b15-bc7e-034a5b428927",

];

const technologyList = [
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/react.png?alt=media&token=fcbc66d3-b712-472c-bc48-29fbcd0a4559",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/pwa.png?alt=media&token=f6a05e5e-e823-478f-a429-c7f7ea050d1e",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/angular.png?alt=media&token=5345b5b5-7b31-4d4b-8d2a-e563bec44500",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/python.png?alt=media&token=7ffcc770-7834-4d9a-bfbe-4a3e9c6d7c75",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ruby.png?alt=media&token=7769db2e-c020-43a8-9eb9-f44a612a44c5",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/appword.png?alt=media&token=462393fb-f0d9-45cb-aab6-39ece486a450",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/threedot.png?alt=media&token=a00dd0e6-8cf5-4088-bc7d-b5cf8235bbd5",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/aws.png?alt=media&token=a91a3c63-a6a1-4f9a-980d-690a6685e970",
  "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/node.png?alt=media&token=b208b1c0-175b-4141-999b-e69ae9c3a54b"
];

const borderText = "You'll be in good company";
const technologies = "T E C H N O L O G I E S";

function HomeTemplate() {
  const {isMobileView} = useIsMobileViewContext();

  const [open, setOpen] = React.useState(false);
  
  const handleOpenModal = () => {
    console.log('click');
    setOpen(state=>!state)
  }

  return (
    <>
      <Modal modalOpen={open} handleOpenModal={handleOpenModal}/>
      <HomePageImage />
      <SlidingData
        list={list}
        borderText={borderText}
        slidesToShow={isMobileView ? 2 : 5}
      />
      <CurrentProjects handleOpenModal={handleOpenModal} />
      <OurExpertise />
      <Technologies
        list={technologyList}
        borderText={technologies}
        slidesToShow={isMobileView ? 2 : 6}
      />
      <Footer />
   
    </>
  );
}

export { HomeTemplate };
