import React from 'react';
import {ImageAtom, TextAtom} from "../view/atoms";

function ProcessWorkCard({img, stage, title}) {
  return <div className=" p-2 lg:my-5 my-2 ">
     <div className="flex justify-center"> 
      <ImageAtom imageStyle="h-20 my-5" img={img} />
      </div>
    <TextAtom divStyle="flex justify-center pt-3" textStyle="text-orange-600 text-sm" text={title}/>
    <TextAtom divStyle="flex justify-center py-1" textStyle="text-gray-800 text-lg" text={ stage}/>
  </div>;
}

export {ProcessWorkCard};
