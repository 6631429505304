import React from 'react';
import {TextAtom} from '../atoms'
import {ClientCard} from "../../components/ClientCard";
import Footer from "../../components/Footer";
import {TextWithRightArrowIcon} from "../molecules";
import Modal from "../../components/Modal";


const expertise = [
  {
    title: "School Management",
   img:"https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/school.jpg?alt=media&token=83c45a22-53d0-466f-a909-b4e4743bfe47"
  },
    {
    title: "Notebook Elite",
   img:"https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/notebook.jpg?alt=media&token=463c9f01-97c3-419d-a6a2-e50190ddde96"
  },
      {
    title: "Big BIking Commune",
   img:"https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/big.jpg?alt=media&token=3881b129-5eb4-431b-9730-d50359b221d4"
  },

      {
    title: "WWW Technologies",
   img:"https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/www.jpg?alt=media&token=a2b26fda-c4cd-4980-85b9-ae016cf68d93"
  },
          {
    title: "Pump Surgeon",
   img:"https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ps.jpg?alt=media&token=9bfec0c6-4fa5-43ff-9690-d74fecb71130"
  },
  {
    title: "NERO Ro",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/neroro.jpg?alt=media&token=835488cb-9c06-4cf7-a040-ec32339ec8d3'
   },
  {
    title: "WWW Tax Consultant",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/www.jpg?alt=media&token=a2b26fda-c4cd-4980-85b9-ae016cf68d93'
   },
  {
    title: "Senica Health Studio",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/shs.jpg?alt=media&token=788c8d6a-a1bb-4da8-8ff3-af76e30d9d79'
   },
  {
    title: "Sencia Studio",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ss.jpg?alt=media&token=bd7170de-9421-494c-b445-32f4bb00a9ed'
   },
  {
    title: "35mm",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/cmm.jpg?alt=media&token=29497910-d85c-46d5-8eb8-e94474f1e0fe'
   },
  {
    title: "New Life Foundation",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/nl.jpg?alt=media&token=5a7d7014-3c5d-4eed-b330-b8e9d8cad6c4'
   },
  {
    title: "Fox Stone",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/foxs.jpg?alt=media&token=4cf32689-28fc-4cc8-a2d9-0da8b735f050'
   },
  {
    title: "AIM HI",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ah.jpg?alt=media&token=2563828a-d434-42ef-a475-13e108ca0116'
   },
  {
    title: "Spark Net English",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/pne.jpg?alt=media&token=e4fc9ecb-f7ba-437e-8a0e-33dc80bfac61'
   },
  {
    title: "Mancincons",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/miacincons.jpg?alt=media&token=f3e96380-1745-40f6-af63-63caa3ef820d'
   },
  {
    title: "QkTrips",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/qkt.jpg?alt=media&token=9f36e64d-529a-4c6d-b57d-96ae99dcc84d'
   },
  {
    title: "VFOCUS",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/vfocus.jpg?alt=media&token=ce803031-467e-468f-9401-560647e758f0'
   },
  {
    title: "QTV",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/qtv.jpg?alt=media&token=0c98d9a9-0ae1-4187-9ce5-abb22fbc9cc1'
   },
  {
    title: "Sri Green Production",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/sgp.jpg?alt=media&token=e8e2f3d1-041d-4ea7-8a10-805eb8991024'
   },
  {
    title: "4A Traders",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/traders.jpg?alt=media&token=30077a2d-6c18-4f5c-948e-a660891442e2'
   },
  {
    title: "Babilav",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/babilav.jpg?alt=media&token=bd746e60-a410-4763-81e6-305560248433'
   },
  {
    title: "30Plus Matrimonial",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/plusm.jpg?alt=media&token=e31bc5b1-f90b-4cae-9e4d-787ecb9d4668'
   },
  {
    title: "Little Peepal",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/lp.jpg?alt=media&token=7481b62e-7d6f-485b-98b7-9fa543802d87'
   },
  {
    title: "Crop Life Bio",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/clb.jpg?alt=media&token=e3418b76-b462-41b9-93e8-86cda7befaaf'
   },
  {
    title: "Four Dots",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/fd.jpg?alt=media&token=63a08b74-30ab-4248-bf74-9dc1b4b3cca7'
   },
  {
    title: "Lakshmi Saai",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/ls.jpg?alt=media&token=7633fc98-2a9e-44d1-8681-0c487f966a9d'
   },
  {
    title: "Lakshmi Store",
    img:'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/o.jpg?alt=media&token=21752b75-815b-40dd-a90e-7c3ddb5e5fbe'
  },
  {
    title: "Perali",
    img: "https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/perali.jpg?alt=media&token=77b21efc-5847-4aa8-81b3-45799f05a528"
  },

]
function ClientsTemplate() {
 
  const [open, setOpen] = React.useState(false);
  
  const handleOpenModal = () => {
    console.log('click');
    setOpen(state=>!state)
  }
  return (
    <>
      <Modal modalOpen={open} handleOpenModal={handleOpenModal}/><div className="flex justify-center items-center p-5">
        <div className=" w-full flex justify-center bg-gradient-to-t from-white via-white to-gray-200 ">
          <div className="py-10 mt-12 lg:mt-10">
  <div className="max-w-screen-xl ">
      <TextAtom divStyle="flex justify-center" textStyle="font-Spartan_semiBold  lg:text-5xl md:text-3xl text-2xl my-5 leading-tight" text="Our Clients" />
      <TextAtom divStyle="flex justify-center lg:pb-5" textStyle="lg:text-2xl text-xl text-center font-gray-800  md:my-5 leading-tight" text="We explore some of the latest trends and strategies" />
      
  <div className="flex flex-wrap lg:justify-start p-5">
    {expertise.map(item => (
    <ClientCard title={item.title} img={item.img}/>
    ))}
   </div>
    </div>
    <TextWithRightArrowIcon handleOpenModal={handleOpenModal} text="Get in Touch"/>
          </div>
          </div>
    </div>
    <Footer />
    </>
  )
}

export {ClientsTemplate};
