import React from 'react';
import {MobileAppsTemplate} from "../view/templates";

function MobileApps() {
  return <div>
    <MobileAppsTemplate/>
  </div>;
}

export default MobileApps;
