import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import kgplus from "../../assets/images/products/kgplus.jpg";
import intelligame from "../../assets/images/products/intelligame.jpg";
import p4u from "../../assets/images/products/p4u.jpg";
import voca from "../../assets/images/products/voca.jpg";
import kgprep from "../../assets/images/products/kgprep.jpg";


const BsCardList = [
    {
        img: intelligame,
        text: 'IntelliGame'
    },

    {
        img: kgplus,
        text: 'KG Plus'
    },
    {
        img: kgprep,
        text: 'KG Prep'
    },
    {
        img: p4u,
        text: 'Planext4u'
    },
    {
        img: voca,
        text: 'VocaBuilder'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/school.jpg?alt=media&token=83c45a22-53d0-466f-a909-b4e4743bfe47',
        text: 'School Management'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/notebook.jpg?alt=media&token=463c9f01-97c3-419d-a6a2-e50190ddde96',
        text: 'Notebook Elite'
    },
    {
        img: 'https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/big.jpg?alt=media&token=3881b129-5eb4-431b-9730-d50359b221d4',
        text: 'Big Biking Commune'
    },
    {
        img: 'https://play-lh.googleusercontent.com/CGoaHXYqiIimNGXftLLBxCCgQ5SoraagiS2oj9KEKHj0oe56LJeowk0ZGgiVIaxZQnA=s180-rw',
        text: 'Rite Angle'
    },
]




export default class AutoPlay extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 3500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className=" w-screen  shadow-xl ">


                <Slider {...settings}>
                    {BsCardList.map((item, idx) => (
                        <div className=" drop-shadow-2xl lg:mb-10  grid lg:grid-cols-4 gap-3 md:grid-cols-2  lg:max-w-screen-2xl shadow-xl  h-full my-2 md:mx-3 rounded-lg mx-auto bg-white">
                            <div className="w-full">

                                <img className="rounded-tr-lg bg-cover rounded-tl-lg w-full bg-cover" src={item.img} />

                            </div>
                            <h1 className="text-2xl font-bold  mx-4 mt-3 md:mb-32">{item.text}</h1></div>
                    ))}
                </Slider>





            </div>
        );
    }
}
