import React from 'react';
import {TextAtom} from "../view/atoms";

import {ImLocation} from "react-icons/im";
import {BsFillTelephoneFill} from "react-icons/bs";
import { FaFacebookF,FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import {Link} from "react-router-dom";



function AddressSocialLink() {
  return (
    <div className="md:flex items-center justify-center lg:justify-around bg-primary py-2">
      <div className="md:flex flex-col md:flex-row items-center justify-center">
        <div className="flex items-center justify-center pr-1 lg:pr-2">
          <ImLocation className="text-gray-900" />
          <TextAtom
            textStyle="px-2 text-sm"
            text="22, Padi Pudu Nagar Main Road Chennai"
          />
        </div>

        <div className="flex items-center justify-center md:pl-1 lg:pl-2">
          <BsFillTelephoneFill className="text-gray-900" />
          <TextAtom textStyle="px-2 text-sm" text="(+91) 7304704039" />
        </div>
      </div>

      <div className="flex justify-center md:py-0 py-5">
        <div className="flex items-center">
          <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
            <FaFacebookF className="mx-2 text-gray-900 hover:text-gray-200" />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
            <FaTwitter className="mx-2 text-gray-900 hover:text-gray-200" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
            <FaInstagram className="mx-2 text-gray-900 hover:text-gray-200" />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
            <FaLinkedinIn className="mx-2 text-gray-900 hover:text-gray-200" />
          </a>
        </div>

        <Link to="/contact">
          <TextAtom
            textStyle="text-sm ml-2 lg:ml-5 cursor-pointer text-white hover:underline underline-offset-4"
            text="Let's Get Together"
          />
        </Link>
      </div>
    </div>
  );
}

export default AddressSocialLink;
