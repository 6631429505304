import React from 'react';
import {ImageAtom, TextAtom} from "../view/atoms";

function ClientCard({title,img}) {
  return <div className="lg:w-3/12 md:w-6/12 w-12/12 md:px-5 my-5">
    <div className="border-2 shadow-xl  md:h-118  lg:h-86 rounded-lg">
      <ImageAtom  divStyle="" imageStyle="w-full h-68 bg-cover rounded-tr-lg rounded-tl-lg" img={img} />
      {/* <div style={{backgroundImage:img,backgroundSize:'cover'}}></div> */}
    <TextAtom divStyle="lg:py-5 md:py-3 py-2 px-3" textStyle="py-2 font-semibold  font-spartan text-xl" text={title} />
</div>
  </div>
}

export {ClientCard};
