import React from 'react';
import {ContactTabOne, ContactTabThree, ContactTabTwo} from "../organisms";
import Footer from '../../components/Footer'

function ContactTemplate() {
  return <div className="md:mt-20">
    <ContactTabOne />
    <ContactTabTwo />
    <ContactTabThree/>
    <Footer/>
  </div>;
}

export {ContactTemplate};
