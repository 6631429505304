import React from 'react';
import {ImageAtom, TextAtom} from "../atoms";
import {TextWithRightArrowIcon, TitlePara} from "../molecules";

const data = [
  {
    title: "Turn-Key",
    para:"Our highly experienced animators have the passion, skill, and expertise to drive your project forward from; concept-to-completion, we move your idea forward with innovation and speed."
  },
  {
    title: "Always Available",
    para:"We are always available to service your needs. You can message or call us 24/7 and you’ll always be able to get a hold of us the same day. We work late and weekends just to make sure your project exceeds your expectations every time."
  },
  {
    title: "Full Support Team",
    para:"We are fully staffed with 3D Artist, Producers, Cartoonists, Illustrators, Web Developer, and Marketing and Branding Industry Experts to ensure your project is a complete success every time. We are proud to be the largest 3D Animation Studio is ISL. Our state-of-the-art animation pipeline, allows Isl to offer a much wider variety of related services than our traditional competitors. ISL is a full-service, concept-to-launch facility. We can create any kind of Animation, Design, illustration, Video Production, FX or graphics you can imagine. If you can dream of it, we can bring it to life. We transform your ideas into reality."
  },
  {
    title: "100% Satisfaction Guarantee",
    para:"We’re so confident you’ll be Wow’d by our services every time, we guarantee that you’ll be 100% satisfied with the Custom Animation and Video we make for you! It’s just that simple. This is an offer you won’t find at other 3D Animation Studios."
  }
]

function AboutUsTabThree({handleOpenModal}) {
  return <div className="bg-blue-600 ">
    <div className="flex justify-center bg-white items-center lg:rounded-bl-25xl rounded-bl-6xl">
      <div className="md:m-10 w-12/12 lg:max-w-screen-xl">
        <div className="lg:flex">
    <div className="lg:w-6/12 lg:mt-0 mt-2 p-5" >
          <TextAtom textStyle="font-nunito_Sans  font-spartan text-4xl leading-10" divStyle="" text="We Are Dedicated to Your Success." />
          <hr className="my-5"/>
      <TextAtom textStyle="font-nunito_Sans text-gray-800" divStyle="my-4 py-5 text-justify" text="We work hard to become a member of your team. We value long term relationships that are built by helping our Customers be successful. We have worked with many of our clients for years and attribute our high client retention rate to our responsiveness to their marketing needs and consistent dedication to their ongoing success. We’re serious when we should be, because great marketing can make the difference between life and death for some businesses and some budgets. You should know, though, that we are sometimes really quite funny. Hilarious really. We’re nice to be around. We enjoy the Customers with whom we work, and (not being conceited or anything) (OK pretty proud but not conceited), we think they enjoy us too."/>
     
    </div>
        <div className="lg:w-6/12 p-10">
          {data.map(item => (
             <TitlePara title={item.title} para={item.para} />
          ))}
         
    </div>
        </div>
         <TextWithRightArrowIcon handleOpenModal={handleOpenModal} text="Contact Us"/>
     </div>
    </div>
    
  </div>;
}

export  {AboutUsTabThree};
