import React from "react";
import { ImageAtom, TextAtom } from "../view/atoms";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

function Footer() {
  return (
    <>
      <div className="lg:flex justify-center bg-gray-200">
        <div className="lg:flex md:flex md:flex-wrap  md:justify-between px-5 lg:max-w-screen-xl py-20 items-center w-12/12 ">
          <div className="md:w-3/12 md:mb-0 mb-5 flex flex-col justify-start p-5 md:p-0">
            <ImageAtom
              divStyle="mb-5 md:mb-2"
              imageStyle="w-40"
              img="https://firebasestorage.googleapis.com/v0/b/illustion-spot-line.appspot.com/o/logo.png?alt=media&token=f684ff19-c320-487f-b32f-d4207958e509"
            />
            <div>
              <TextAtom
                textStyle="text-sm text-gray-700"
                text="22, Padi Pudu Nagar, Main Road Anna Nagar West Ext, Chennai"
              />
            </div>
            <div>
              <TextAtom
                textStyle="text-sm text-gray-700"
                text="info@illusionspotline.com"
              />
            </div>
            <div>
              <TextAtom textStyle="text-sm text-gray-700" text="+91 7904704039" />
            </div>
          </div>
          <div className="md:w-3/12 md:mb-0 mb-5 p-5 md:p-0">
            <TextAtom
              divStyle="pb-3"
              textStyle="font-semibold text-lg"
              text="Services"
            />
            <TextAtom
              divStyle="pb-1"
              textStyle="text-sm text-gray-700 hover:text-primary cursor-pointer"
              text="Branding"
            />
            <TextAtom
              divStyle="pb-1"
              textStyle="text-sm text-gray-700 hover:text-primary cursor-pointer"
              text="Web Designing"
            />
            <TextAtom
              divStyle="pb-1"
              textStyle="text-sm text-gray-700 hover:text-primary cursor-pointer"
              text="Mobile Application"
            />
            <TextAtom
              divStyle="pb-1"
              textStyle="text-sm text-gray-700 hover:text-primary cursor-pointer"
              text="Game Development"
            />
          </div>

          <div className="lg:w-3/12 md:w-5/12 p-5 md:p-0">
            <TextAtom divStyle="pb-3" textStyle="font-semibold" text="Connect" />
            <div className="flex items-center mb-2">
              <FaFacebookF className="mr-2 w-8 h-8 border-2 p-1 rounded-full border-black hover:bg-black hover:text-white transform ease-in-out duration-500" />
              <FaTwitter className="mx-2 w-8 h-8 border-2 p-1 rounded-full border-black hover:bg-black hover:text-white transform ease-in-out duration-500" />
              <FaInstagram className="mx-2 w-8 h-8 border-2 p-1 rounded-full border-black hover:bg-black hover:text-white transform ease-in-out duration-500" />
              <FaLinkedinIn className="mx-2 w-8 h-8 border-2 p-1 rounded-full border-black hover:bg-black hover:text-white transform ease-in-out duration-500" />
            </div>

            <TextAtom
              textStyle="text-sm"
              text="We bring the years, global experience, and stamina to guide our clients through new and often disruptive realities."
            />
          </div>
        </div>
      </div>
      <div className="lg:flex justify-center bg-darkblue font-nunito_Sans">
        <div className="md:flex-row flex flex-col md:justify-between px-5 lg:max-w-screen-xl py-4 items-center w-full text-gray-100 text-sm">
          <span>©2022 Illusion Spot Line . All rights reserved</span>
          <span className="hover:underline underline-offset-2 cursor-pointer text-sm">
            Teams &amp; condition | Privacy Policy
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;
