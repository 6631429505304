import React from 'react';
import {AboutTemplate} from "../view/templates";

function About() {
  return <div>
    <AboutTemplate/>
  </div>;
}

export default About;
