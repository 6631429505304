import React from 'react';
import {TextAtom} from "../view/atoms";

function BrandingCard({title,para, img}) {
  return <div className="border-1 border-gray-600 rounded-md p-4 shadow-lg">
    <div className="w-20 h-20 bg-contain" style={{backgroundImage:`url('${img}')`}}/>
    <TextAtom divStyle="py-2" textStyle="text-2xl font-semibold " text={title}/>
    <TextAtom textStyle="text-md font-nunito_Sans text-gray-700 text-justify" text={para}/>
  </div>;
}

export {BrandingCard};
